import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {PopupboxContainer, PopupboxManager} from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import {GlobalStylePopupbox} from "../../styles/GlobalStylesPopupbox";
import {
  ContDetalles,
  ContDetallesNumeros,
  ContItemDetalles,
  ContLoteMetrosCuadrados,
  ContNombreCasa,
  ItemDetalles,
  ItemDetallesIco,
  ContDetallesNumerosTitulo,
  ContDetallesNumerosValor,
  GlobalStylesItemTabsCasas,
  ContImgPlantasDeCasa,
  ImgPlantasDeCasa,
  ContTextoPlantas,
  TextoPlantas,
  ContPlantasCasa,
  BtnLightbox, ContBtnsLightbox
} from "./stylesItemTabsCasas";
import check from '../../images/punta-mar/check.svg';
import plantaBaja from '../../images/punta-mar/casa-a-planta-baja.jpg';
import plantaAlta from '../../images/punta-mar/casa-a-planta-alta.jpg';
import fileAlberca from '../../images/punta-mar/casa-a-alberca.jpg';
import fileSala from '../../images/punta-mar/casa-a-sala.jpg';
import fileCocina from '../../images/punta-mar/casa-a-cocina.jpg';
import fileRecamara from '../../images/punta-mar/casa-a-recamara-principal.jpg';
import fileBanio from '../../images/punta-mar/casa-a-banio-principal.jpg';

const ItemTabsCasaPunta = ({data}) => {
  const fnShowPlantas = (val) => {
    //console.log(val);
    if(val === 0){
      document.getElementById('contPlantaBajaCasaA').classList.remove('display-no');
      document.getElementById('contPlantaAltaCasaA').classList.add('display-no');

      document.getElementById('btnPlantaBajaCasaA').classList.add('negrita');
      document.getElementById('btnPlantaAltaCasaA').classList.remove('negrita');
    }else{
      document.getElementById('contPlantaAltaCasaA').classList.remove('display-no');
      document.getElementById('contPlantaBajaCasaA').classList.add('display-no');

      document.getElementById('btnPlantaAltaCasaA').classList.add('negrita');
      document.getElementById('btnPlantaBajaCasaA').classList.remove('negrita');
    }
  }

  const openPopupbox = (title, file, css) => {
    const content = <img className={css} src={file} />;
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: title
        },
        fadeIn: true,
        fadeInSpeed: 500
      }
    })
  }

  return (
    <Container isFluid={true} className={' margin-bottom-plantas'}>
      <GlobalStylePopupbox/>
      <GlobalStylesItemTabsCasas/>
      <Columns className={'columns-margin-side-no'}>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>

          <Container className={'container-item-casas'} isFluid={true}>
            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <ContNombreCasa>
                  Casa Punta
                </ContNombreCasa>
                <ContLoteMetrosCuadrados>
                  Lote de 200m2
                </ContLoteMetrosCuadrados>
              </Column>
            </Columns>

            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>2 Recamaras con walking closet</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>2 baños completos</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Sala / Comedor</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Alberca privada</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Estacionamiento para dos vehículos</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Patio con acceso independiente</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Cocina integral</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Cuarto de servicios</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>1 medio baño</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
            </Columns>

            <Columns
              className={'columns-margin-side-no display-flex cont-details-numbers mgt-details-numbers'}
              isMultiline={true}
            >
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                <ContDetallesNumeros>
                  <ContDetallesNumerosTitulo>
                    TOTAL:
                  </ContDetallesNumerosTitulo>
                  <ContDetallesNumerosValor>
                    227 m2
                  </ContDetallesNumerosValor>
                </ContDetallesNumeros>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                <ContDetallesNumerosTitulo>
                  PLANTA BAJA:
                </ContDetallesNumerosTitulo>
                <ContDetallesNumerosValor>
                  99.34 m2
                </ContDetallesNumerosValor>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                <ContDetallesNumerosTitulo>
                  NIVEL 1:
                </ContDetallesNumerosTitulo>
                <ContDetallesNumerosValor>
                  106.89 m2
                </ContDetallesNumerosValor>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                <ContDetallesNumerosTitulo>
                  NIVEL 2:
                </ContDetallesNumerosTitulo>
                <ContDetallesNumerosValor>
                  20.77 m2
                </ContDetallesNumerosValor>
              </Column>
            </Columns>

          </Container>

        </Column>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>
          <ContPlantasCasa>
            <ContImgPlantasDeCasa id={'contPlantaBajaCasaA'}>
              <ImgPlantasDeCasa src={plantaBaja} alt={''} />
              <ContBtnsLightbox>
                <BtnLightbox id={'casa-a-alberca'} onClick={() => openPopupbox('Alberca', fileAlberca, 'popupbox-img-height')}>+</BtnLightbox>
                <BtnLightbox id={'casa-a-sala'} onClick={() => openPopupbox('Sala', fileSala, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-a-cocina'} onClick={() => openPopupbox('Cocina', fileCocina, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>
            </ContImgPlantasDeCasa>

            <ContImgPlantasDeCasa id={'contPlantaAltaCasaA'} className={'display-no'}>
              <ImgPlantasDeCasa src={plantaAlta} alt={''} />
              <ContBtnsLightbox>
                <BtnLightbox id={'casa-a-recamara'} onClick={() => openPopupbox('Recamara Principal', fileRecamara, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-a-banio'} onClick={() => openPopupbox('Baño Principal', fileBanio, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>
            </ContImgPlantasDeCasa>

            <ContTextoPlantas>
              <TextoPlantas id={'btnPlantaBajaCasaA'} onClick={() => fnShowPlantas(0)} className={'negrita'}>Planta Baja</TextoPlantas>
              <TextoPlantas id={'btnPlantaAltaCasaA'} onClick={() => fnShowPlantas(1)}>Planta Alta</TextoPlantas>
            </ContTextoPlantas>
          </ContPlantasCasa>
        </Column>

      </Columns>

      <PopupboxContainer />

    </Container>
  )
}

export default ItemTabsCasaPunta
