import styled, {createGlobalStyle} from 'styled-components';
import {Tabs, TabList, Tab, TabPanels, TabPanel} from "@reach/tabs";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStyleTabsCasas = createGlobalStyle`
  .tab-active{
    color: ${GlobalColors.colorLinkHover} !important;
  }
`

export const MyTabs = styled(Tabs)`
  background-color: ${GlobalBackgroundColors.bgPrimary};
`
export const MyTabList = styled(TabList)`
  border-bottom: 3px solid #FFFFFF;
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const MyTab = styled(Tab)`
  width: 245px;
  height: 110px;
  
  font-family: ${GlobalFonts.fontRegular};
  color: #FAFAFA;
  cursor: pointer;
  
  & .tab-active,
  & :hover{
    color: ${GlobalColors.colorLinkHover};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 18px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
export const MyTabPanels = styled(TabPanels)`
  
`
export const MyTabPanel = styled(TabPanel)`
  
  @media(min-width: 300px) and (max-width: 767px){
    padding: 30px 0 0 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 30px 0 0 0;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 40px 0 0 0;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 50px 0 0 0;
  }
  @media(min-width: 1408px){
    padding: 50px 0 0 0;
  }
`
