import styled, { createGlobalStyle }  from 'styled-components';
import {GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStylesItemTabsCasas = createGlobalStyle`
  .display-no{
    display: none !important;
  }
  .margin-bottom-plantas{
    margin-bottom: 100px;
  }
  .container-item-casas{
    padding-top: 30px;
  }
  .cont-details-numbers .column{
    @media(min-width: 300px) and (max-width: 767px){
      padding-left: 10% !important;
      margin-bottom: 30px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      padding-left: 10% !important;
      margin-bottom: 30px;
    }
  }
  .mgt-details-numbers{
    @media(min-width: 300px) and (max-width: 767px){
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
  }
  
  #casa-a-alberca{
    @media(min-width: 300px) and (max-width: 767px){
      top: 140px;
      left: 200px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 300px;
      left: 425px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 250px;
      left: 260px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 260px;
      left: 315px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 300px;
      left: 365px;
    }
    @media(min-width: 1920px){
      top: 300px;
      left: 365px;
    }
  }
  #casa-a-sala{
    @media(min-width: 300px) and (max-width: 767px){
      top: 100px;
      left: 117px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 230px;
      left: 260px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 205px;
      left: 160px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 200px;
      left: 200px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 230px;
      left: 235px;
    }
    @media(min-width: 1920px){
      top: 230px;
      left: 235px;
    }
  }
  #casa-a-cocina{
    @media(min-width: 300px) and (max-width: 767px){
      top: 10px;
      left: 280px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 35px;
      left: 599px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 85px;
      left: 370px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 50px;
      left: 450px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 60px;
      left: 525px;
    }
    @media(min-width: 1920px){
      top: 60px;
      left: 530px;
    }
  }
  #casa-a-recamara{
    @media(min-width: 300px) and (max-width: 767px){
      top: 100px;
      left: 115px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 215px;
      left: 255px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 195px;
      left: 154px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 190px;
      left: 185px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 215px;
      left: 215px;
    }
    @media(min-width: 1920px){
      top: 215px;
      left: 215px;
    }
  }
  #casa-a-banio{
    @media(min-width: 300px) and (max-width: 767px){
      top: 18px;
      left: 103px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 50px;
      left: 228px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 94px;
      left: 137px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 65px;
      left: 167px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 73px;
      left: 200px;
    }
    @media(min-width: 1920px){
      top: 73px;
      left: 200px;
    }
  }
  
  #casa-b-cocina{
    @media(min-width: 300px) and (max-width: 767px){
      top: 10px;
      left: 282px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 35px;
      left: 605px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 96px;
      left: 370px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 56px;
      left: 450px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 60px;
      left: 525px;
    }
    @media(min-width: 1920px){
      top: 60px;
      left: 530px;
    }
  }
  #casa-b-recamara{
    @media(min-width: 300px) and (max-width: 767px){
      top: 100px;
      left: 115px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 215px;
      left: 255px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 195px;
      left: 154px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 190px;
      left: 185px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 215px;
      left: 215px;
    }
    @media(min-width: 1920px){
      top: 215px;
      left: 215px;
    }
  }
  #casa-b-banio{
    @media(min-width: 300px) and (max-width: 767px){
      top: 18px;
      left: 103px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 50px;
      left: 228px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 94px;
      left: 137px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 65px;
      left: 167px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 73px;
      left: 200px;
    }
    @media(min-width: 1920px){
      top: 73px;
      left: 200px;
    }
  }
  
  /* Casa C */
  #casa-c-fachada-principal{
    @media(min-width: 300px) and (max-width: 767px){
      top: 10px;
      left: 10px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 10px;
      left: 10px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 75px;
      left: 10px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 45px;
      left: 10px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 45px;
      left: 10px;
    }
    @media(min-width: 1920px){
      top: 45px;
      left: 10px;
    }
  }
  #casa-c-fachada-posterior{
    @media(min-width: 300px) and (max-width: 767px){
      top: 10px;
      right: 10px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 10px;
      right: 10px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 75px;
      right: 10px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 45px;
      right: 10px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 45px;
      right: 10px;
    }
    @media(min-width: 1920px){
      top: 45px;
      right: 10px;
    }
  }
  #casa-c-sala{
    @media(min-width: 300px) and (max-width: 767px){
      top: 30px;
      left: 195px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 75px;
      left: 420px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 120px;
      left: 255px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 80px;
      left: 310px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 100px;
      left: 360px;
    }
    @media(min-width: 1920px){
      top: 100px;
      left: 360px;
    }
  }
  #casa-c-recamara{
    @media(min-width: 300px) and (max-width: 767px){
      top: 120px;
      left: 55px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 250px;
      left: 130px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 230px;
      left: 75px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 240px;
      left: 90px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 250px;
      left: 110px;
    }
    @media(min-width: 1920px){
      top: 260px;
      left: 110px;
    }
  }
  #casa-c-banio{
    @media(min-width: 300px) and (max-width: 767px){
      top: 120px;
      left: 165px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 250px;
      left: 335px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 230px;
      left: 210px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 240px;
      left: 250px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 250px;
      left: 290px;
    }
    @media(min-width: 1920px){
      top: 260px;
      left: 300px;
    }
  }
  #casa-c-recamara-2{
    @media(min-width: 300px) and (max-width: 767px){
      top: 120px;
      left: 265px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 250px;
      left: 570px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 230px;
      left: 350px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 240px;
      left: 420px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 250px;
      left: 495px;
    }
    @media(min-width: 1920px){
      top: 260px;
      left: 500px;
    }
  }  
  /* Casa D */
  #casa-d-fachada-principal{
    @media(min-width: 300px) and (max-width: 767px){
      bottom: 10px;
      left: 10px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      bottom: 10px;
      left: 10px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      bottom: 75px;
      left: 10px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      bottom: 45px;
      left: 10px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      bottom: 45px;
      left: 10px;
    }
    @media(min-width: 1920px){
      bottom: 45px;
      left: 10px;
    }
  }
  #casa-d-fachada-posterior{
    @media(min-width: 300px) and (max-width: 767px){
      bottom: 10px;
      right: 10px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      bottom: 10px;
      right: 10px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      bottom: 75px;
      right: 10px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      bottom: 45px;
      right: 10px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      bottom: 45px;
      right: 10px;
    }
    @media(min-width: 1920px){
      bottom: 45px;
      right: 10px;
    }
  }
  #casa-d-sala{
    @media(min-width: 300px) and (max-width: 767px){
      top: 120px;
      left: 180px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 250px;
      left: 400px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 230px;
      left: 245px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 230px;
      left: 300px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 260px;
      left: 350px;
    }
    @media(min-width: 1920px){
      top: 260px;
      left: 350px;
    }
  }
  #casa-d-comedor{
    @media(min-width: 300px) and (max-width: 767px){
      top: 20px;
      left: 130px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 40px;
      left: 270px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 80px;
      left: 170px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 60px;
      left: 200px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 60px;
      left: 240px;
    }
    @media(min-width: 1920px){
      top: 60px;
      left: 240px;
    }
  }  
  
  #casa-d-recamara{
    @media(min-width: 300px) and (max-width: 767px){
      top: 50px;
      left: 190px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 125px;
      left: 415px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 140px;
      left: 250px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 120px;
      left: 300px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 120px;
      left: 300px;
    }
    @media(min-width: 1920px){
      top: 140px;
      left: 360px;
    }
  }
  
  
`

export const ContNombreCasa = styled.div`
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorGold};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 28px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 30px;
  }
  @media(min-width: 1408px){
    font-size: 35px;
  }
`
export const ContLoteMetrosCuadrados = styled.div`
  font-family: ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 30px;
    font-size: 18px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 18px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px){
    font-size: 18px;
  }
`
export const ContDetalles = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 85%;
    /*padding: 30px 0 30px 0;*/
    padding: 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 40%;
    /*padding: 30px 0 30px 0;*/
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 40px 0 40px 0;    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 50px 0 40px 0;
  }
  @media(min-width: 1408px){
    padding: 50px 0 40px 0;
  }
`
export const ContItemDetalles = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: normal;
  justify-content: flex-start;
`
export const ItemDetalles = styled.div`
  position: relative;
  margin-top: -3px;
  
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorGold};
`
export const ItemDetallesIco = styled.div`
  position: relative;
  margin-right: 15px;
  width: 20px;
  height: 20px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
`
export const ContDetallesNumeros = styled.div`
  position: relative;
  display: flex;
  align-items: normal;
  justify-content: center;
  flex-direction: column;
`
export const ContDetallesNumerosTitulo = styled.div`
  font-family: ${GlobalFonts.fontLight};
  font-size: 16px;
  color: ${GlobalColors.colorGold};
`
export const ContDetallesNumerosValor = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 16px;
  color: ${GlobalColors.colorPrimary};
`
export const ContPlantasCasa = styled.div`
  width: 100%;
  height: 100%;
  
  margin-bottom: 50px;
  
  display: flow-root;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    padding-top: 0px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 30px;
    padding-top: 0px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 75px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 120px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding-top: 125px;
  }
  @media(min-width: 1920px){
    padding-top: 125px;
  }
`
export const ContImgPlantasDeCasa = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImgPlantasDeCasa = styled.img`
  width: 100%;
  height: auto;
`
export const ContTextoPlantas = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const TextoPlantas = styled.div`
  font: 22px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  cursor: pointer;
`
export const ContBtnsLightbox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
export const BtnLightbox = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  padding-bottom: 5px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border: 2px solid ${GlobalColors.colorPrimary};
  border-radius: 50%;
  background-color: ${GlobalColors.colorTertiary};
  
  font: 28px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};
  
  cursor: pointer;
  transition: .3s;
  
  &:hover{
    width: 43px;
    height: 43px;
  }
`
export const ContImgCasa2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
export const ImgCasa2 = styled.img`
  width: 100%;
  height: 100%;
`

