import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {PopupboxContainer, PopupboxManager} from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"
import {GlobalStylePopupbox} from "../../styles/GlobalStylesPopupbox";
import {
  ContDetalles,
  ContDetallesNumeros,
  ContItemDetalles,
  ContLoteMetrosCuadrados,
  ContNombreCasa,
  ItemDetalles,
  ItemDetallesIco,
  ContDetallesNumerosTitulo,
  ContDetallesNumerosValor,
  GlobalStylesItemTabsCasas,
  ContImgPlantasDeCasa,
  ImgPlantasDeCasa,
  ContTextoPlantas,
  TextoPlantas,
  ContPlantasCasa,
  BtnLightbox, ContBtnsLightbox
} from "./stylesItemTabsCasas";
import check from '../../images/punta-mar/check.svg';
import plantaBaja from '../../images/punta-mar/casa-c-planta-baja.jpg';
import plantaAlta from '../../images/punta-mar/casa-c-planta-alta.jpg';
import fileFachadaPrincipal from '../../images/punta-mar/casa-c-fachada-principal.jpg';
import fileFachadaPosterior from '../../images/punta-mar/casa-c-fachada-posterior.jpg';
import fileSala from '../../images/punta-mar/casa-c-sala.jpg';
import fileRecamara from '../../images/punta-mar/casa-c-recamara-principal.jpg';
import fileRecamara2 from '../../images/punta-mar/casa-c-recamara-dos.jpg';
import fileBanio from '../../images/punta-mar/casa-c-banio-principal.jpg';

const ItemTabsCasaCielo = ({data}) => {
  const fnShowPlantas = (val) => {
    //console.log(val);
    if(val === 0){
      document.getElementById('contPlantaBajaCasaC').classList.remove('display-no');
      document.getElementById('contPlantaAltaCasaC').classList.add('display-no');

      document.getElementById('btnPlantaBajaCasaC').classList.add('negrita');
      document.getElementById('btnPlantaAltaCasaC').classList.remove('negrita');
    }else{
      document.getElementById('contPlantaAltaCasaC').classList.remove('display-no');
      document.getElementById('contPlantaBajaCasaC').classList.add('display-no');

      document.getElementById('btnPlantaAltaCasaC').classList.add('negrita');
      document.getElementById('btnPlantaBajaCasaC').classList.remove('negrita');
    }
  }

  const openPopupbox = (title, file, css) => {
    const content = <img className={css} src={file} />;
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: title
        },
        fadeIn: true,
        fadeInSpeed: 500
      }
    })
  }

  return (
    <Container isFluid={true} className={' margin-bottom-plantas'}>
      <GlobalStylePopupbox/>
      <GlobalStylesItemTabsCasas/>
      <Columns className={'columns-margin-side-no'}>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>

          <Container className={'container-item-casas'} isFluid={true}>
            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <ContNombreCasa>
                  Casa Cielo
                </ContNombreCasa>
                <ContLoteMetrosCuadrados>
                  Lote de 200m2
                </ContLoteMetrosCuadrados>
              </Column>
            </Columns>

            <Columns className={'columns-margin-side-no'}>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>2 Recamaras con balcón</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Recámara principal con walking closet</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Baño con wc & regadera independiente •2 Baños completos</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>1 Medio baño</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Sala / Comedor</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 6, desktop: 6, widescreen: 6}}>
                <ContDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Cocina integral <i>(Sin electrodomésticos)</i></ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Estacionamiento para 2 vehículos</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Estancia con doble altura</ItemDetalles>
                  </ContItemDetalles>
                  <ContItemDetalles>
                    <ItemDetallesIco src={check}/>
                    <ItemDetalles>Cuarto de servicio y lavandería</ItemDetalles>
                  </ContItemDetalles>
                </ContDetalles>
              </Column>
            </Columns>

            <Columns
              className={'columns-margin-side-no display-flex cont-details-numbers mgt-details-numbers'}
              isMultiline={true}
            >
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 4, widescreen: 4}}>
                <ContDetallesNumeros>
                  <ContDetallesNumerosTitulo>
                    TOTAL:
                  </ContDetallesNumerosTitulo>
                  <ContDetallesNumerosValor>
                    180.55 m2
                  </ContDetallesNumerosValor>
                </ContDetallesNumeros>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 4, widescreen: 4}}>
                <ContDetallesNumerosTitulo>
                  PLANTA BAJA:
                </ContDetallesNumerosTitulo>
                <ContDetallesNumerosValor>
                  74.55 m2
                </ContDetallesNumerosValor>
              </Column>
              <Column className={'global-columns-paddign-no'} isSize={{mobile: 6, tablet: 6, desktop: 4, widescreen: 4}}>
                <ContDetallesNumerosTitulo>
                  NIVEL 1:
                </ContDetallesNumerosTitulo>
                <ContDetallesNumerosValor>
                  105.99 m2
                </ContDetallesNumerosValor>
              </Column>
            </Columns>

          </Container>

        </Column>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 2, desktop: 6, widescreen: 6}}>
          <ContPlantasCasa>
            <ContImgPlantasDeCasa id={'contPlantaBajaCasaC'}>
              <ImgPlantasDeCasa src={plantaBaja} alt={''} />
              <ContBtnsLightbox>
                <BtnLightbox id={'casa-c-fachada-principal'} onClick={() => openPopupbox('Fachada Principal', fileFachadaPrincipal, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-fachada-posterior'} onClick={() => openPopupbox('Fachada Posterior', fileFachadaPosterior, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-sala'} onClick={() => openPopupbox('Sala', fileSala, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>
            </ContImgPlantasDeCasa>

            <ContImgPlantasDeCasa id={'contPlantaAltaCasaC'} className={'display-no'}>
              <ImgPlantasDeCasa src={plantaAlta} alt={''} />
              <ContBtnsLightbox>
                <BtnLightbox id={'casa-c-recamara'} onClick={() => openPopupbox('Recamara Principal', fileRecamara, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-recamara-2'} onClick={() => openPopupbox('Recamara Dos', fileRecamara2, 'popupbox-img-width')}>+</BtnLightbox>
                <BtnLightbox id={'casa-c-banio'} onClick={() => openPopupbox('Baño Principal', fileBanio, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>
            </ContImgPlantasDeCasa>

            <ContTextoPlantas>
              <TextoPlantas id={'btnPlantaBajaCasaC'} onClick={() => fnShowPlantas(0)} className={'negrita'}>Planta Baja</TextoPlantas>
              <TextoPlantas id={'btnPlantaAltaCasaC'} onClick={() => fnShowPlantas(1)}>Planta Alta</TextoPlantas>
            </ContTextoPlantas>
          </ContPlantasCasa>
        </Column>

      </Columns>

      <PopupboxContainer />

    </Container>
  )
}

export default ItemTabsCasaCielo
