import React from "react"
import {Container, Columns, Column} from 'bloomer';
import {
  ContAmenidades,
  ContAmenidadesItem,
  ContAmenidadesItems,
  ContDetallesCasas,
  ContFigDer,
  ContFigIzq,
  ContImgCasa1,
  ContLinea,
  ContLogoPuntaMar,
  ContMetros,
  ContMetrosTextoSecundario,
  ContNumeros,
  ContPuntaMar,
  ContTextoAmenidades,
  ContTextoMetros,
  ContTextoNumeros,
  ContTextoNumerosPlus,
  ContTextoPuntaMar,
  GlobalStyle,
  ImgFigDer,
  ImgFigIzq,
  Linea,
  ContPuntaMarPlanes,
  TextoPlanes,
  TextoVivirEn,
  ContButtonPlanes,
  ContForm,
  TextoPorqueVivirEn,
  ContNameAmenities,
  ContDescNumeros,
  ContPrinTextoNumeros
} from "./styles";
import logoPuntaMar from '../../images/punta-mar/logo-punta-mar.svg';
import backPuntaMar from '../../images/punta-mar/back-punta-mar.jpg';
import figIzq from '../../images/punta-mar/fig-izq.svg';
import figDer from '../../images/punta-mar/fig-der.svg';
import ameSol from '../../images/punta-mar/amenidades-sol.svg';
import ameYoga from '../../images/punta-mar/amenidades-yoga.svg';
import ameAlberca from '../../images/punta-mar/amenidades-alberca.svg';
import amePlaya from '../../images/punta-mar/amenidades-playa.svg';
import ameServicio from '../../images/punta-mar/amenidades-servicio.svg';
import ameBar from '../../images/punta-mar/amenidades-bar.svg';
import ameHamaca from '../../images/punta-mar/amenidades-hamaca.svg';
import ameCoWork from '../../images/punta-mar/amenidades-co-work.svg';
import casa1 from '../../images/punta-mar/casa-1.jpg';
import backPlanes from '../../images/punta-mar/back-punta-mar-planes.jpg';
import trenCasas from '../../images/punta-mar/tren-de-casas.jpg';

import TabsCasas from "./tabsCasas";
import ButtonGuzbarraf from "../UI/Button";
import ContactoComp from "../Contacto";

const handlePlanes = () => {
  var elmnt = document.getElementById("frmContact");
  elmnt.scrollIntoView({behavior: "smooth"});
  setTimeout(function(){
    document.getElementById('txtNombre').focus();
  }, 1000);
}

{
  /*
  The new Luxury Wave of coastal living
 Wander, don't run; Following your dreams and finding your passions. That is the magic of Oaxaca Coast where you can live your dreams. Finding passion in your beach home can  be so fulfilling. Passion is about finding what you love -- in work, in relationships, in life.
   */

  /*
  For the good life of the new coastal habit of the Oaxacan coast. It is located in the Mexican Pacific, a place full of tranquility and security. A life near Puerto Escondido where you can enjoy the private family experience, events and parties. Enjoy the Oaxacan gastronomic world,
with a long tradition of delicious surprises. Live the exciting world of extreme sports such as surfing, diving, snorkeling, ah and the adventure of fishing. In the southern mountains that borders the coastal coastline, practice hard-core motocross, where you can also do hacking or hiking.
The practice of Yoga is part of our culture of the spirit and of the body. We have professional tennis courts where you can develop your tennis. Very close to the Laguna de Manialtepec, it is a mini paradise, to ski, practice paddle tennis or stroll through the mangroves by boat.
The night in Puerto Escondido offers an exotic nightlife with several musical proposals and in December live the Puerto de Cine Festival. A Life with spectacular landscapes. Punta Mar, the new coastal habit!
  */
}
const langPorQueVivirEn = {
  es: `¿Por Que Vivir en Punta Mar?`,
  en: `Coastal lifestyle`
}
const langASolo = {
  es: `A solo`,
  en: `Just`
}
const langPlayaDelfines = {
  es: `De playa Delfines`,
  en: `Delfines beach`
}

const langAmenidades = {
  es: `Amenidades`,
  en: `Amenities`
}
const langTextoPuntaMar = {
  en: `Experience your best life throughout the new coastal lifestyle of the Oaxacan coast. It is located on the Southern Mexican Pacific Coast, a place full of tranquility and security. A life near Puerto Escondido where you can enjoy the private family experience, events and parties. Enjoy the Oaxacan gastronomic world, with a long tradition of delicious surprises. 
  <br/><br/>
  Live the exciting world of extreme sports such as surfing, diving, snorkeling, ah and the adventure of deep-sea fishing. We also have just minutes away the southern mountains that borders the coastline where you can practice hard-core motocross, and incredible nature hikes! The practice of Yoga is a big part of our culture for the spirit and the body as well.  We  also have professional tennis courts where you can develop your tennis skills. 
  <br/><br/>
  Close by, you can find the Laguna de Manialtepec which is a mini paradise that offers skiing, paddle tennis or a relaxing stroll through the mangroves by boat. This specific lagoon is known for a rare phenomenon where you can witness plankton that glows in the water during certain times of the year, it’s a must!
  <br/><br/>
  Once the sun sets, Puerto Escondido offers an exotic nightlife with several musical proposals and in December experience the Puerto Cinema Festival, which is a must! Don’t miss out on this incredible Lifestyle with spectacular landscapes and much more.  Punta Mar, the new coastal habit!`
  ,
  es: `Por la buena vida del nuevo habito costero de la costa Oaxaqueña. Está ubicada en el Pacifico mexicano un lugar lleno de tranquilidad y seguridad. Una vida cerca de Puerto Escondido donde puedes disfrutar de la experiencia privada familiar, eventos y fiestas. Gozar del mundo gastronómico oaxaqueño, con una larga tradición de deliciosas sorpresas.
  <br/><br/>
  Vivir el apasionante mundo de los deportes extremos como el surf, bucear, snorkelear, ah y la aventura de la pesca. En la sierra sur que colinda con el litoral costero, practicar el motocross en duro, donde también puedes hacer hacking o senderismo. La práctica del Yoga es ya parte de la cultura del espíritu y del cuerpo. Tenemos canchas profesionales de tenis donde puedes desarrollar tú tenis.
  <br/><br/>
  Muy cerca la Laguna de Manialtepec, es un mini paraíso, para skiar, practicar paddle o pasear por los manglares en lancha. La noche en Puerto Escondido ofrece una exótica vida nocturna con varias propuestas musicales y en diciembre vivir el Festival del Puerto de cine. Una vida con paisajes espectaculares. ¡Punta Mar El nuevo habito costero!
  <br/><br/>
  <b>En Punta Mar se Vive Mar, Belleza y Cultura.</b>
  <br/>
  <b>Punta Mar tu  life style costero</b>
  `
  }
;


const PuntaMar = (props) => {
  console.log('Acerca -> props => ', props);
  console.log('langKey => ', props.pageContext.langKey);

  const lang = props.pageContext.langKey;

  return (
    <Container className={'back-punta-mar'} isFluid={true}>
      <GlobalStyle/>
      <Columns className={'columns-margin-side-no'}>
        <Column className={'global-columns-paddign-no'}>
          <ContPuntaMar src={backPuntaMar}>
            <ContLogoPuntaMar>
              <img src={logoPuntaMar}/>
            </ContLogoPuntaMar>
            <TextoPorqueVivirEn>
              {langPorQueVivirEn[lang]}
            </TextoPorqueVivirEn>
            <ContTextoPuntaMar dangerouslySetInnerHTML={{ __html: langTextoPuntaMar[lang] }}></ContTextoPuntaMar>
          </ContPuntaMar>
        </Column>
      </Columns>

      <Columns className={'columns-margin-side-no display-flex'}>

        <Column
          className={'global-columns-paddign-no'}
          isSize={{mobile: 3, tablet: 3, desktop: 4, widescreen: 4}}
        >
          <ContFigIzq>
            <ImgFigIzq src={figIzq}/>
          </ContFigIzq>
        </Column>

        <Column
          className={'global-columns-paddign-no'}
          isSize={{mobile: 6, tablet: 6, desktop: 4, widescreen: 4}}
        >
          <ContMetros>
            <ContMetrosTextoSecundario>{langASolo[lang]}</ContMetrosTextoSecundario>
            <ContTextoMetros>700 Mts</ContTextoMetros>
            <ContMetrosTextoSecundario>{langPlayaDelfines[lang]}</ContMetrosTextoSecundario>
          </ContMetros>
        </Column>

        <Column
          className={'global-columns-paddign-no cont-fig-der'}
          isSize={{mobile: 3, tablet: 3, desktop: 4, widescreen: 4}}
        >
          <ContFigDer>
            <ImgFigDer src={figDer}/>
          </ContFigDer>
        </Column>

      </Columns>



      <ContImgCasa1 bgImg={trenCasas}>
        {/*<img src={casa1} alt={''}/>*/}
      </ContImgCasa1>



      <ContAmenidades>
        <Container className={'full-height'}>

          <Columns className={'columns-margin-side-no full-height'} isMultiline={true}>

            <Column
              className={'global-columns-paddign-no full-height'}
              isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}
            >
              <ContTextoAmenidades>
                {langAmenidades[lang]}
              </ContTextoAmenidades>
            </Column>

            <Column
              className={'global-columns-paddign-no'}
              isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}
            >
              <ContAmenidadesItems>
                <Columns isMultiline={true}>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameSol} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Roof Top</div>
                        <div>Infinity pool</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameYoga} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Fitness Room</div>
                        <div>&nbsp;</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameAlberca} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Relaxation Pool</div>
                        <div>&nbsp;</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={amePlaya} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Green Area</div>
                        <div>&nbsp;</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>

                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameServicio} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Front Desk</div>
                        <div>Service</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameCoWork} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Lounge area</div>
                        <div>& Co-Work</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameBar} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Sunset Roof</div>
                        <div>Terrace</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                  <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
                    <ContAmenidadesItem>
                      <div><img src={ameHamaca} alt={''}/></div>
                      <ContNameAmenities>
                        <div>Hammock &</div>
                        <div>Lounge area</div>
                      </ContNameAmenities>
                    </ContAmenidadesItem>
                  </Column>
                </Columns>
              </ContAmenidadesItems>
            </Column>

          </Columns>

        </Container>
      </ContAmenidades>

      <ContNumeros>
        <Container className={'full-height'}>
          <Columns className={'columns-margin-side-no full-height display-flex'} isMultiline={false}>

            <Column
              className={'global-columns-paddign-no full-height'}
              isSize={{mobile: 5, tablet: 5, desktop: 5, widescreen: 5}}
            >
              <ContPrinTextoNumeros>
                <ContTextoNumeros>
                  26<ContTextoNumerosPlus>+</ContTextoNumerosPlus>
                </ContTextoNumeros>
                <ContDescNumeros>
                  Casas exclusivas con cuatro modelos distintos
                </ContDescNumeros>
              </ContPrinTextoNumeros>
            </Column>

            <Column
              className={'global-columns-paddign-no full-height'}
              isSize={{mobile: 2, tablet: 2, desktop: 2, widescreen: 2}}
            >
              <ContLinea>
                <Linea/>
              </ContLinea>
            </Column>

            <Column
              className={'global-columns-paddign-no full-height'}
              isSize={{mobile: 5, tablet: 5, desktop: 5, widescreen: 5}}
            >
              <ContPrinTextoNumeros>
                <ContTextoNumeros>
                  20<ContTextoNumerosPlus>+</ContTextoNumerosPlus>
                </ContTextoNumeros>
                <ContDescNumeros>
                  Departamentos modulables de lujo
                </ContDescNumeros>
              </ContPrinTextoNumeros>
            </Column>

          </Columns>
        </Container>
      </ContNumeros>

      <ContImgCasa1 bgImg={casa1}>
        {/*<img src={casa1} alt={''}/>*/}
      </ContImgCasa1>

      <ContDetallesCasas>
        <Container>
          <TabsCasas/>
        </Container>
      </ContDetallesCasas>

      <Columns className={'columns-margin-side-no'}>
        <Column className={'global-columns-paddign-no'}>
          <ContPuntaMarPlanes src={backPlanes}>
            <TextoVivirEn>
              Vivir en Punta Mar es muy simple
            </TextoVivirEn>
            <TextoPlanes>
              Planes de financiamiento
            </TextoPlanes>
            <ContButtonPlanes>
              <ButtonGuzbarraf
                text={'OBTENER UN PLAN AHORA'}
                bgHover={''}
                onClick={() => handlePlanes()}
              />
            </ContButtonPlanes>
          </ContPuntaMarPlanes>
        </Column>
      </Columns>

      <ContForm>
        <ContactoComp/>
      </ContForm>

    </Container>
  )
}
export default PuntaMar
