import React from "react"
import {MyTabs, MyTabList, MyTab, MyTabPanels, MyTabPanel, GlobalStyleTabsCasas} from "./stylesTabsCasas";
import ItemTabsCasaPunta from "./itemTabsCasaPunta";
import ItemTabsCasaMar from "./itemTabsCasaMar";
import ItemTabsCasaCielo from './itemTabsCasaCielo';
import ItemTabsCasaTierra from './itemTabsCasaTierra';

const TabsCasas = () => {
  const fnTabClick = (val) => {
    console.log('fnTabClick =>', `tab-${val}`);
    const myClass = 'tab-active';
    const elements = document.querySelectorAll('.tabs-casas');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove(myClass);

      if (i === (val - 1)) {
        elements[i].classList.add('tab-active');
      }
    }

    // document.getElementById(`tab-${val}`).classList.add('tab-active');
  }

  React.useEffect(() => {
    //window.addEventListener('keydown', handleKeyDown);
    const elements = document.querySelectorAll('.tabs-casas');

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", function() {
        fnTabClick(i + 1);
      });
    }

    // cleanup this component
    return () => {
      //window.removeEventListener('keydown', handleKeyDown);
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", fnTabClick);
      }
    };
  }, []);

  return (
    <MyTabs>
      <GlobalStyleTabsCasas/>
      <MyTabList>
        <MyTab id={'tab-1'} className={'tabs-casas tab-active'}>CASA PUNTA</MyTab>
        <MyTab id={'tab-2'} className={'tabs-casas'}>CASA MAR</MyTab>
        <MyTab id={'tab-3'} className={'tabs-casas'}>CASA CIELO</MyTab>
        <MyTab id={'tab-4'} className={'tabs-casas'}>CASA TIERRA</MyTab>
        {/*<MyTab id={'tab-4'} className={'tabs-casas not-allowed'} disabled>CASA TIERRA<br/>Segunda etapa</MyTab>*/}
      </MyTabList>

      <MyTabPanels>
        <MyTabPanel>
          <ItemTabsCasaPunta/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaMar/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaCielo/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsCasaTierra/>
        </MyTabPanel>
      </MyTabPanels>
    </MyTabs>
  )
}
export default TabsCasas
