import styled, {createGlobalStyle} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStyle = createGlobalStyle`
  .back-punta-mar{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .cont-fig-der{
    display: flex;
    align-items: flex-end;
  }
`

export const ContPuntaMar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 180px;
    padding-bottom: 100px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 250px;
    padding-bottom: 250px;
  }
  @media(min-width: 1408px){
    padding-top: 250px;
    padding-bottom: 250px;
  }
`
export const ContLogoPuntaMar =styled.div`
  margin-bottom: 50px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 250px;
    height: 230px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 260px;
    height: 240px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 300px;
    height: 280px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 300px;
    height: 280px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    height: 280px;
  }
  @media(min-width: 1920px){
    width: 300px;
    height: 280px;
  }
`
export const TextoPorqueVivirEn = styled.div`
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 25px;
    font-size: 26px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 25px;
    font-size: 26px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-bottom: 25px;
    font-size: 26px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-bottom: 25px;
    font-size: 26px;
  }
  @media(min-width: 1408px){
    margin-bottom: 25px;
    font-size: 26px;
  }
`
export const ContTextoPuntaMar = styled.div`
  
  font-family: ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    font-size: 17px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
    font-size: 17px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 70%;
    font-size: 17px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 600px;
    font-size: 17px;
  }
  @media(min-width: 1408px){
    width: 700px;
    font-size: 17px;
  }
`


export const ContMetros = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 350px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 450px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 550px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 850px;
  }
  @media(min-width: 1408px){
    height: 850px;
  }
`
export const ContTextoMetros = styled.div`
  text-align: center;
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorTertiary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 60px;
    line-height: 70px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 70px;
    line-height: 80px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 80px;
    line-height: 90px;
  }
  @media(min-width: 1408px){
    font-size: 80px;
    line-height: 90px;
  }
`
export const ContMetrosTextoSecundario = styled.div`
  text-align: center;
  font-family: ${GlobalFonts.fontSecondary};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 26px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 30px;
  }
  @media(min-width: 1408px){
    font-size: 30px;
  }
`
export const ContFigIzq = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  
  @media(min-width: 300px) and (max-width: 767px){
    top: 110px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    top: 100px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    top: 60px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    top: 150px;
  }
  @media(min-width: 1408px){
    top: 150px;
  }
`
export const ImgFigIzq = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`
export const ContFigDer = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  
  @media(min-width: 300px) and (max-width: 767px){
    top: 170px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    top: 120px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    top: 60px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    top: -90px;
  }
  @media(min-width: 1408px){
    top: -90px;
  }
`
export const ImgFigDer = styled.img`
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
`

export const ContAmenidades = styled.div`
  position: relative;
  width: 100%;
  background-color: #1E3C43;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: auto;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 350px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 400px;
  }
  @media(min-width: 1408px){
    height: 400px;
  }
`
export const ContTextoAmenidades = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorGold};
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 180px;
    font-size: 40px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 250px;
    font-size: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 60px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 70px;
  }
  @media(min-width: 1408px){
    font-size: 70px;
  }
`
export const ContAmenidadesItems = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-bottom: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-bottom: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-bottom: 50px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContAmenidadesItem = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  
  display: contents;
  align-items: center;
  justify-content: center;
  
  & > div:nth-child(1){
    text-align: center;
  }
`
export const ContNameAmenities = styled.div`
  color: ${GlobalColors.colorPrimary};
  text-align: center;
`
export const ContNumeros = styled.div`
  position: relative;
  width: 100%;
  
  background-color: #18434B;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 250px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 300px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 350px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 400px;
  }
  @media(min-width: 1408px){
    height: 400px;
  }
`
export const ContPrinTextoNumeros = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContTextoNumeros = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorGold};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 70px;
    line-height: 80px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 100px;
    line-height: 110px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 110px;
    line-height: 120px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 130px;
    line-height: 140px;
  }
  @media(min-width: 1408px){
    font-size: 130px;
    line-height: 140px;
  }
`
export const ContTextoNumerosPlus = styled.div`
  position: relative;
  
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorGold};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 60px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 70px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 80px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 100px;
  }
  @media(min-width: 1408px){
    font-size: 100px;
  }
`
export const ContDescNumeros = styled.div`
  position: relative;
  width: 60%;
  
  margin: 0 auto;
  
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 16px;
    line-height: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 18px;
    line-height: 22px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 22px;
    line-height: 26px;
  }
  @media(min-width: 1408px){
    font-size: 24px;
    line-height: 28px;
  }
`
export const ContLinea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Linea = styled.div`
  position: relative;
  width: 2px;
  height: 70%;
  
  background-color: ${GlobalColors.colorGold};
`

export const ContImgCasa1 = styled.div`
  position: relative;
  width: 100%;
  
  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 300px;
    background-position: center left;
    background-size: cover;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 350px;
    background-position: center left;
    background-size: cover;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 400px;
    background-position: center left;
    background-size: cover;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 450px;
    background-position: center left;
    background-size: cover;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    height: 500px;
    background-position: center left;
    background-size: cover;
  }
  @media(min-width: 1920px){
    height: 550px;
    background-position: center left;
    background-size: cover;
  }
`
export const ContDetallesCasas = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  
  background-color: ${GlobalBackgroundColors.bgPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ContPuntaMarPlanes = styled.div`

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 200px;
    padding-bottom: 200px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 220px;
    padding-bottom: 220px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 250px;
    padding-bottom: 250px;
  }
  @media(min-width: 1408px){
    padding-top: 250px;
    padding-bottom: 250px;
  }
`
export const TextoVivirEn = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorLinkHover};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 26px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 30px;
  }
  @media(min-width: 1408px){
    font-size: 30px;
  }
`
export const TextoPlanes = styled.div`
  font-family: ${GlobalFonts.fontSecondary};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 40px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 80px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 80px;
  }
  @media(min-width: 1408px){
    font-size: 80px;
  }
`
export const ContButtonPlanes = styled.div`
  
`
export const ContForm = styled.div`
  min-height: 425px;
  
  background-color: ${GlobalBackgroundColors.bgContact};
`

